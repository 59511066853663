<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Aporte - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>
              

                <b-col md="6">
                  <b-form-group>
                    <label>Socio: <span @click="modalClients" class="text-info link">Nuevo</span></label>
                    <v-select placeholder="Seleccione un socio" class="w-100" :filterable="false" label="full_name" v-model="partner" @search="SearchPartner" :options="partners"></v-select>
                    <small v-if="errors.id_partner" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Boleta:">
                    <b-form-input class="text-center" readonly type="text" v-model="contribution.number"></b-form-input>
                    <small v-if="errors.number"  class="form-text text-danger" >Ingrese un numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Medio de Pago:">
                    <b-form-select  v-model="contribution.payment_method" :options="payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label=".">
                    <b-button @click="ModalContributionShow" type="button" variant="primary" class="form-control">Aportes</b-button>
                  </b-form-group>
                </b-col>
               
                <b-col md="8">
                  <b-form-group label="Observación:">
                    <b-form-textarea type="text" v-model="contribution.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Anexos:">
                     <b-form-file
                      accept="application/pdf"
                      v-model="file"
                      :state="Boolean(file)"
                      @change="onFileChange"
                      placeholder="Selecciona un archivo"
                      drop-placeholder="Arrastre el archivo aqui"
                    ></b-form-file>
                  </b-form-group>
                </b-col>

                <ContributionDetail />
                <b-col md="12" class="text-center">
                  <small v-if="errors.contributions_detail"  class="form-text text-danger" >Ingrese aportes</small>
                </b-col>
                

                <b-col md="10">
                </b-col>

                <b-col md="2">
                  <b-form-group label="Total">
                    <b-form-input type="number" class="text-right" disabled step="any" v-model="total"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
    <ModalContribution />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
import ModalContribution from './components/ModalContribution'
import ContributionDetail from './components/ContributionDetail'
export default {
  name: "UsuarioAdd",
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
      ModalContribution,
      ContributionDetail,
  },
  data() {
    return {
      isLoading: false,
      module: 'Contribution',
      role: 2,
      contribution: {
          id_contribution:'',
          id_partner:'',
          number:'',
          observation:'',
          file:'',
          payment_method:'008',
          total:'0.00',
          state:1,
      },
      file:null,
      partners: [],
      partner:null,
      payment_method: [
        {value :"008", text :'EFECTIVO'},
        {value :"007", text :'CHEQUE'},
        {value :"001", text :'DEPÓSITO EN CUENTA'},
        {value :"003", text :'TRANSFERENCIA DE FONDOS'},
      ],
      twist_type:[
        {value:'1',text:'Sepelio'},
        {value:'2',text:'Liquidación de Aportes'},
        {value:'3',text:'Gasto Administrativo'},
        {value:'4',text:'Prestamo Ordinario'},
      ],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],

      errors: {
        id_partner: false,
        payment_method: false,
        number: false,
        contributions_detail: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.GetCorrelative();
    this.mLoadResetContributionDetail();
  },
  methods: {
    GetCorrelative,
    ModalContributionShow,
    AddContribution,
    Validate,
    SearchPartner,
    modalClients,
    onFileChange,

    DataPrint,
    Print,

     ...mapActions('Contribution',['mLoadResetContributionDetail']),
  },

  computed: {
    ...mapState('Contribution',['contributions_detail','total']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};
function GetCorrelative() {
  let me = this;
  let url = me.url_base + "get-correlative/Contribution";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.contribution.number = response.data.result.number;
      }else{
        me.contribution.number = '';
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}


function onFileChange(e) {
  this.contribution.file = e.target.files[0];
}

function ModalContributionShow() {
  if (this.partner != null) {
    EventBus.$emit('ModalContributionShow',this.partner.id, this.role);
  }
}

function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function SearchPartner(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.partners = response.data;
            loading(false);
      })
    }
}


function AddContribution() {
  let me = this;
  let url = me.url_base + "contribution/add";

  let data = new FormData();
  data.append("id_partner", me.partner.id);
  data.append("id_user", me.user.id_user);
  data.append("contributions_detail", JSON.stringify(me.contributions_detail));
  data.append("total", me.total);
  data.append("number", me.contribution.number);
  data.append("payment_method", me.contribution.payment_method);
  data.append("observation", me.contribution.observation);
  data.append("file", me.contribution.file);
  data.append("broadcast_date", me.contribution.broadcast_date);
  data.append("state", me.contribution.state);

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {

          me.contribution.id_contribution = '';
          me.contribution.id_partner = '';
          me.contribution.payment_method = '008';
          me.contribution.number = '';
          me.contribution.observation = '';
          me.contribution.file = '';
          me.contribution.total = '0.00';
          me.contribution.state = 1;
          me.partner = null;
          me.file = null;
          me.GetCorrelative();
          me.mLoadResetContributionDetail();
          me.DataPrint(response.data.result.id_contribution);
        
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.payment_method = this.contribution.payment_method.length == 0 ? true : false;
  this.errors.number = this.contribution.number.length == 0 ? true : false;
  this.errors.contributions_detail = this.contributions_detail.length == 0 ? true : false;
  this.errors.total = this.total.length == 0 ? true : false;

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_method) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.contributions_detail) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el aporte ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddContribution();
    }
  });
}

function DataPrint(id_contribution) {
  let me = this;
  let url = me.url_base + "contribution/data-print/"+id_contribution;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.Print(response.data.result)
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = true;
  });
}


function Print(info) {
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("info",JSON.stringify(info)); 

  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}
</script>
